import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { ICountry,IStates } from 'src/app/Shared/models/MarketInfoModel';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})

export class AdminMarketInfoService {
  private baseURL: string;
  constructor(private http: HttpClient) {
    this.baseURL = environment.apiBaseUrl;
  }
  public marketTerm;
  hideBackButton=false;
  private options = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
  
  getMarketTerms(marketId){
    return this.http.get<any>(
      `${this.baseURL}/General/GetMarketBoothTerm?marketId=` + marketId,
      { observe: 'response' })
      .pipe(
        map(res => {
         const marketTerm = res;
          return marketTerm;
        }),
        catchError(this.handleError)
      );
  }

  getMarketList(): Observable<boolean> {
    return this.http.get<any>(
      `${this.baseURL}/MarketInfo/GetMarketList`,
      { observe: 'response' })
      .pipe(
        map(res => {
          const marketInfo = res.body as any;
          return marketInfo;
        }),
        catchError(this.handleError)
      );
  }

  getMarketDetails(marketId): Observable<boolean> {
    return this.http.get<any>(
      `${this.baseURL}/MarketInfo/GetMarketDetails?marketId=` + marketId,
      { observe: 'response' })
      .pipe(
        map(res => {
          const marketDetails = res.body as any;
          return marketDetails;
        }),
        catchError(this.handleError)
      );
  }

  getMarketInternetSettings(marketId): Observable<boolean> {
    return this.http.get<any>(
      `${this.baseURL}/MarketInfo/GetMarketInternetSettings?marketId=` + marketId,
      { observe: 'response' })
      .pipe(
        map(res => {
          const marketInternetSettings = res.body as any;
          return marketInternetSettings;
        }),
        catchError(this.handleError)
      );
  }
  
  saveMarketDetails(marketData): Observable<any> {
    const options = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
    const url = `${this.baseURL}/MarketInfo/SaveMarketDetails`;
    return this.http.post<any>(url, marketData, options)
      .pipe(
        map(res => {
          const response = res as any;
          return response;
        }),
        catchError(this.handleError)
      );
  }

  saveMarketInternetDetails(marketInternetData): Observable<any> {
    const options = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
    const url = `${this.baseURL}/MarketInfo/SaveMarketInternetSettings`;
    return this.http.post<any>(url, marketInternetData, options)
      .pipe(
        map(res => {
          const response = res as any;
          return response;
        }),
        catchError(this.handleError)
      );
  }

  getCountries(): Observable<ICountry[]> {
    return this.http.get<ICountry[]>(
      `${this.baseURL}/General/GetCountries`,
      { observe: 'response' })
      .pipe(
        map(res => {
          const Countries = res.body as ICountry[];
          return Countries;
        }),
        catchError(this.handleError)
      );
  }

  getUSStates(): Observable<IStates[]> {
    return this.http.get<IStates[]>(
      `${this.baseURL}/General/GetUSStates`,
      { observe: 'response' })
      .pipe(
        map(res => {
          const states = res.body as IStates[];
          return states;
        }),
        catchError(this.handleError)
      );
  }

  getMarketTimeZones(): Observable<any[]> {
    return this.http.get<any[]>(
      `${this.baseURL}/MarketInfo/GetMarketTimeZone`,
      { observe: 'response' })
      .pipe(
        map(res => {
          const timeZones = res.body as any[];
          return timeZones;
        }),
        catchError(this.handleError)
      );
  }

  getMarketDays(marketId): Observable<any[]> {
    return this.http.get<any[]>(
      `${this.baseURL}/MarketInfo/GetMarketDays?marketId=` + marketId,
      { observe: 'response' })
      .pipe(
        map(res => {
          const marketDays = res.body as any[];
          return marketDays;
        }),
        catchError(this.handleError)
      );
  }

  saveMarketDays(marketDays): Observable<any> {
    const options = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
    const url = `${this.baseURL}/MarketInfo/SaveMarketDays`;
    return this.http.post<any>(url, marketDays, options)
      .pipe(
        map(res => {
          const response = res as any;
          return response;
        }),
        catchError(this.handleError)
      );
  }

  saveMarketLogo(marketlogo): Observable<any> {
    const options = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
    const url = `${this.baseURL}/MarketInfo/SaveMarketlogo`;
    return this.http.post<any>(url, marketlogo, options)
      .pipe(
        map(res => {
          const response = res as any;
          return response;
        }),
        catchError(this.handleError)
      );
  }

  updateProductCategory(categoryData): Observable<any> {
    const options = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
    const url = `${this.baseURL}/admin/ProductCategory/UpdateProductCategory`;
    return this.http.post<any>(url, categoryData, options)
      .pipe(
        map(res => {
          const response = res as any;
          return response;
        }),
        catchError(this.handleError)
      );
  }

  checkingBookingExists(marketDate,marketId): Observable<boolean> {
    return this.http.get<boolean>(
      `${this.baseURL}/MarketInfo/CheckingBookingExists?marketDate=${marketDate}&marketId=` + marketId,
      { observe: 'response' })
      .pipe(
        map(res => {
          const isExist = res.body as boolean
          return isExist;
        }),
        catchError(this.handleError)
      );
  }

  getMarketProductCategory(marketId): Observable<boolean> {
    return this.http.get<any>(
      `${this.baseURL}/admin/ProductCategory/GetMarketProductCategory?marketId=` + marketId,
      { observe: 'response' })
      .pipe(
        map(res => {
          const categoryList = res.body as any;
          return categoryList;
        }),
        catchError(this.handleError)
      );
  }

  getMarketLogo(marketId): Observable<any[]> {
    return this.http.get<any[]>(
      `${this.baseURL}/MarketInfo/GetMarketLogo?marketId=` + marketId,
      { observe: 'response' })
      .pipe(
        map(res => {
          const marketLogo= res.body as any[];
          return marketLogo;
        }),
        catchError(this.handleError)
      );
  }

  getMarketCount(): Observable<any[]> {
    return this.http.get<any[]>(
      `${this.baseURL}/MarketInfo/GetCurrentMarketCount`,
      { observe: 'response' })
      .pipe(
        map(res => {
          const timeZones = res.body as any[];
          return timeZones;
        }),
        catchError(this.handleError)
      );
  }

  private handleError(error: HttpErrorResponse) {
    console.error('server error:', error);
    if (error.error instanceof Error) {
      const errMessage = error.error.message;
      // this.appinsights.logEvent(errMessage);
      return throwError(errMessage);
    }
    return throwError(error || 'Node.js server error');
  }
}
