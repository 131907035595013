import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppService } from '../app.service';
import { Observable, catchError, map, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class VoidTransactionService {
  private baseURL: string; 
  constructor(private http: HttpClient,private appService: AppService) {
         this.baseURL = environment.apiBaseUrl;
  }
  private options = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
  
  GetReceipts(searchOption): Observable<any> {
    return this.http.post<any>(
        `${this.baseURL}/AccountReceipt/GetAccountReceipts`,searchOption,
        { observe: 'response' })
        .pipe(
          map(res => {
            const result = res.body as any;
            return result;
          }),
          catchError(this.handleError)
        );
  }

  GetMarketAccountReceiptId(AccountReceiptId:number): Observable<any> {
    return this.http.get<any>(
        `${this.baseURL}/AccountReceipt/GetMarketAccountReceiptId?AccountReceiptId=${AccountReceiptId}`,
        { observe: 'response' })
        .pipe(
          map(res => {
            const result = res.body as any;
            return result;
          }),
          catchError(this.handleError)
        );
  }
  LoadTransctionDetails(Id:number): Observable<any> {
    return this.http.get<any>(
        `${this.baseURL}/AccountReceipt/LoadTransactionDetails?Id=${Id}`,
        { observe: 'response' })
        .pipe(
          map(res => {
            const result = res.body as any;
            return result;
          }),
          catchError(this.handleError)
        );
  }
  VoidTransction(TransactionDetails): Observable<any> {
    const options = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
    const url = `${this.baseURL}/AccountReceipt/VoidTransaction`;
    return  this.http.post<any>(url, TransactionDetails, options)
        .pipe(
          map(res => {
            
            const result = res as any;
            return result;
          }),
          catchError(this.handleError)
        );
  }

  GetRefundCCDetails(Id:number): Observable<any> {
    return this.http.get<any>(
        `${this.baseURL}/AccountReceipt/GetRefundCCDetails?Id=${Id}`,
        { observe: 'response' })
        .pipe(
          map(res => {
            const result = res.body as any;
            return result;
          }),
          catchError(this.handleError)
        );
  }

  GetTransactionByReceiptId(Id:number): Observable<any> {
    return this.http.get<any>(
        `${this.baseURL}/AccountReceipt/GetTransactionByReceiptId?Id=${Id}`,
        { observe: 'response' })
        .pipe(
          map(res => {
            const result = res.body as any;
            return result;
          }),
          catchError(this.handleError)
        );
  }

  private handleError(error: HttpErrorResponse) {
    console.error('server error:', error);
    if (error.error instanceof Error) {
      const errMessage = error.error.message;
     // this.appinsights.logEvent(errMessage);
      return throwError(errMessage);
    }
    return throwError(error || 'Node.js server error');
  }
}
