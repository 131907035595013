<app-loader *ngIf="loaderIcon | async"></app-loader>
<app-top-bar AdminOrOps="Operation" CurrentPage="Payments" *ngIf="!boothTransfer"></app-top-bar>
<form [formGroup]="paymentForm">
    <div id="ContainerMain" class="container dashBoardMainClass divVendorsPayment" *ngIf="!processPayment && !showSignatureComponent">
        <div class="container" style="min-height: 0px;">
            <div class="row">
                <div class="col-sm-12 col-md-12 col-lg-5 col-xl-5 text-left mt-2">
                    <div class="row">
                        <div class="col-4 text-left">
                            <label><b>Vendor Name</b></label>
                        </div>
                        <div class="col-1 text-center">
                            <label><b>:</b></label>
                        </div>
                        <div class="col-6 text-left">
                            <label>{{vendorName}}</label>
                        </div>
                    </div>
                </div>
                <div class="col-sm-12 col-md-12 col-lg-5 col-xl-5 text-left mt-2">
                    <div class="row">
                        <div class="col-4 text-left">
                            <label><b>Vendor ID</b></label>
                        </div>
                        <div class="col-1 text-center">
                            <label><b>:</b></label>
                        </div>
                        <div class="col-6 text-left">
                            <label>{{vendorMarketID}}</label>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="container borderPayments mt-2 mb-4 paymentClass" formGroupName="paymentDetails">
            <div class="row">
                <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12 text-left">
                    <label class="paymentHead mt-2" i18n>PAYMENTS</label>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-12 col-md-12 col-lg-4 col-xl-4 text-left">
                    <div class="row mt-2">
                        <div class="col-sm-12 col-md-12 col-lg-5 col-xl-5 text-left mt-2">
                            <label class="totalDue" i18n>Total Due</label>
                        </div>
                        <div class="col-sm-12 col-md-12 col-lg-7 col-xl-7 text-left">
                            <input type="text" class="form-control text-right" readonly  formControlName="totalDue" (keypress)="numberOnly($event)">
                        </div>
                    </div>
                    <div class="row mt-2">
                        <div class="col-sm-12 col-md-12 col-lg-5 col-xl-5 text-left mt-2">
                            <label i18n>Cash</label>
                        </div>
                        <div class="col-sm-12 col-md-12 col-lg-7 col-xl-7 text-left">
                            <input type="text" class="form-control text-right" formControlName="cashAmount" (keypress)="allowNegetiveCash ? numberOnlyForCash($event) :numberOnly($event)"
                            (blur)="setMinimum(paymentForm.controls.paymentDetails.get('cashAmount'))" (click)="handleClick($event)" (dblclick)="handledblClick($event)" (change)="setMinimum(paymentForm.controls.paymentDetails.get('cashAmount'))">
                        </div>
                    </div>
                    <div class="row mt-2">
                        <div class="col-sm-12 col-md-12 col-lg-5 col-xl-5 text-left mt-2">
                            <label i18n>Check</label>
                        </div>
                        <div class="col-sm-12 col-md-12 col-lg-7 col-xl-7 text-left">
                            <input type="text" class="form-control text-right" formControlName="checkAmount" (keypress)="numberOnly($event)"
                            (blur)="setMinimum(paymentForm.controls.paymentDetails.get('checkAmount'))" (click)="handleClick($event)" (dblclick)="handledblClick($event)" (change)="setMinimum(paymentForm.controls.paymentDetails.get('checkAmount'))">
                        </div>
                    </div>
                    <div class="row mt-2">
                        <div class="col-sm-12 col-md-12 col-lg-5 col-xl-5 text-left mt-2">
                            <label i18n>Credit Card</label>
                        </div>
                        <div class="col-sm-12 col-md-12 col-lg-7 col-xl-7 text-left">
                            <input type="text" class="form-control text-right" formControlName="creditCardAmount" (keypress)="numberOnly($event)" (input)="setCurrencyFormat(paymentForm.controls.paymentDetails.get('creditCardAmount'))"
                            (blur)="setMinimum(paymentForm.controls.paymentDetails.get('creditCardAmount'))" (click)="handleClick($event)" (dblclick)="handledblClick($event)" (change)="setMinimum(paymentForm.controls.paymentDetails.get('creditCardAmount'))">
                        </div>
                    </div>
                    <div class="row mt-2">
                        <div class="col-sm-12 col-md-12 col-lg-5 col-xl-5 text-left mt-2">
                            <label i18n>Direct Debit</label>
                        </div>
                        <div class="col-sm-12 col-md-12 col-lg-7 col-xl-7 text-left">
                            <input type="text" class="form-control text-right" formControlName="directDebitAmount" (keypress)="numberOnly($event)" (input)="setCurrencyFormat(paymentForm.controls.paymentDetails.get('directDebitAmount'))"
                            (blur)="setMinimum(paymentForm.controls.paymentDetails.get('directDebitAmount'))" (click)="handleClick($event)" (dblclick)="handledblClick($event)" (change)="setMinimum(paymentForm.controls.paymentDetails.get('directDebitAmount'))">
                        </div>
                    </div>
                    <div class="row mt-2" *ngIf="applyCCCharge">
                        <div class="col-sm-12 col-md-12 col-lg-5 col-xl-5 text-left mt-2">
                            <label i18n>Convenience Fee</label>
                        </div>
                        <div class="col-sm-12 col-md-12 col-lg-7 col-xl-7 text-left">
                            <input type="text" class="form-control text-right" formControlName="convenienceFee" readonly (keypress)="numberOnly($event)"
                            (blur)="setMinimum(paymentForm.controls.paymentDetails.get('convenienceFee'))" (click)="handleClick($event)" (dblclick)="handledblClick($event)" (change)="setMinimum(paymentForm.controls.paymentDetails.get('convenienceFee'))">
                        </div>
                    </div>
                    <div class="row mt-2" *ngIf="calculateChangeAmount">
                        <div class="col-sm-12 col-md-12 col-lg-5 col-xl-5 text-left mt-2">
                            <label i18n>Change Amount</label>
                        </div>
                        <div class="col-sm-12 col-md-12 col-lg-7 col-xl-7 text-left">
                            <input type="text" class="form-control text-right" formControlName="changeAmount" readonly (keypress)="numberOnly($event)"
                            (blur)="setMinimum(paymentForm.controls.paymentDetails.get('changeAmount'))" (click)="handleClick($event)" (dblclick)="handledblClick($event)" (change)="setMinimum(paymentForm.controls.paymentDetails.get('changeAmount'))">
                        </div>
                    </div>
                    <div class="row mt-2">
                        <div class="col-sm-12 col-md-12 col-lg-5 col-xl-5 text-left mt-2">
                            <label style="font-weight: 600; font-size: 16px;" i18n>Balance Amount</label>
                        </div>
                        <div class="col-sm-12 col-md-12 col-lg-7 col-xl-7 text-left">
                            <input type="text" class="form-control text-right" readonly  formControlName="balanceAmount" (keypress)="numberOnly($event)">
                        </div>
                    </div>
                </div>
                <div class="col-sm-12 col-md-12 col-lg-4 col-xl-4 text-left">
                    <div class="row mt-2">
                        <div class="col-sm-12 col-md-12 col-lg-4 col-xl-4 text-left">
                            <label i18n>Check #</label>
                        </div>
                        <div class="col-sm-12 col-md-12 col-lg-8 col-xl-8 text-left">
                            <input type="text" class="form-control" #checkNo (keydown)="onCheckNumKeyPress($event)" formControlName="checkNo">
                        </div>
                    </div>
                    <div class="row mt-2">
                        <div class="col-sm-12 col-md-12 col-lg-4 col-xl-4 text-left">
                            <label i18n>Card Type</label>
                        </div>
                        <div class="col-sm-12 col-md-12 col-lg-8 col-xl-8 text-left">
                            <select class="form-control" formControlName="creditCardTypes" (change)="checkPaymentType()">
                                <option *ngFor="let item of creditCardTypes" [value]="item.CreditCardTypeId">
                                    {{item.CompanyCreditCardTypeDesc}}</option>
                            </select>
                        </div>
                    </div>

                    <div class="row mt-2" *ngIf="applyCCCharge">
                        <div class="col-sm-12 col-md-12 col-lg-4 col-xl-4 text-left">
                            <label i18n>Cards</label>
                        </div>
                        <div class="col-sm-12 col-md-12 col-lg-8 col-xl-8 text-left">
                            <select class="form-control" formControlName="CreditCards" (change)="calculateConvenienceFee()">
                                <option *ngFor="let item of CreditCardList" [value]="item.CreditCardTypeId">
                                    {{item.CreditCardTypeDesc}}</option>
                            </select>
                        </div>
                    </div>

                    <div class="row mt-2">
                        <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12 text-left">
                            <div class="checkbox-wrapper">
                                <label class="pure-material-checkbox">                                        
                                    <input type="checkbox" formControlName="keyedInTransaction" (change)="checkkeyedInTransaction()">
                                    <span i18n>Keyed in transaction</span></label>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-sm-12 col-md-12 col-lg-4 col-xl-4 text-left mblreceiptOptions" *ngIf="!boothTransfer">
                    <div class="receiptOptions">
                        <div class="row pt-2 pb-1">
                            <div class="col-sm-12 col-md-12 col-lg-5 col-xl-5 text-left">
                                <div class="checkbox-wrapper mt-2" *ngIf="IsQDR">
                                    <label class="pure-material-checkbox">                                        
                                        <input type="checkbox" formControlName="PrintBillNum">
                                    <span i18n>Print Booth Numbers on Bill</span></label>
                                </div>
                                <div class="checkbox-wrapper mt-2">
                                    <label class="pure-material-checkbox">                                        
                                        <input type="checkbox" formControlName="displayReceipt">
                                    <span i18n>Display Receipt</span></label>
                                </div>
                                <div class="checkbox-wrapper mt-3">
                                    <label class="pure-material-checkbox">                                        
                                        <input type="checkbox" formControlName="printReceipt">
                                        <span i18n>Print Receipt</span></label>
                                </div>
                                <div class="checkbox-wrapper mt-3 mb-2">
                                    <label class="pure-material-checkbox">                                        
                                        <input type="checkbox" formControlName="emailReceipt">
                                        <span i18n>Email Receipt</span></label>
                                </div>
                            </div>
                            <div class="col-sm-12 col-md-12 col-lg-6 col-xl-6 text-left">
                                <div class="checkbox-wrapper mt-2">
                                    <label class="pure-material-checkbox">                                        
                                        <input type="checkbox" formControlName="displayContract">
                                        <span i18n>Display Contract</span></label>
                                </div>
                                <div class="checkbox-wrapper mt-3">
                                    <label class="pure-material-checkbox">                                        
                                        <input type="checkbox" formControlName="printContract">
                                        <span i18n>Print Contract</span></label>
                                </div>
                                <div class="checkbox-wrapper mt-3 mb-2">
                                    <label class="pure-material-checkbox">                                       
                                        <input type="checkbox" formControlName="emailContract">
                                     <span i18n>Email Contract</span></label>
                                </div>
                            
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-6 col-md-6 col-lg-6 col-xl-6 text-left mt-4 mb-2">
                    <label i18n style="color: blue;font-weight: bold;">
                        F2-Cash&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;F3-Check&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;F4-Credit&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;F5-Direct Debit
                    </label>
                </div>

                <div class="col-sm-6 col-md-6 col-lg-6 col-xl-6 text-right mb-2">
                    <div style="padding: 5px;" *ngIf="DisablePayment"><small style="color:red;" i18n>* Total payment amount should match the total due</small></div>
                    <button class="btn btn-primary mr-1 btnPayNow mt-3" i18n (click)="SubmitRental()" [disabled]="DisablePayment || disablePayBtnAfterSubmit">Pay Now</button>
                    <button class="btn btn-primary mr-1 btnCancel mt-3" i18n (click)="ClearPay()">Clear</button>
                    <button class="btn btn-primary mt-3" i18n (click)="goBack()">Back</button>
                </div>
            </div>
        </div>
    </div>

    <div *ngIf="processPayment">
        <app-payment [VendorId]="vendorId" [keyedInTransaction]="keyedInTransaction" [isQDR]="IsQDR" [CCAmount]="creditCardAmount" [TransactionType]="transactionType" [voidPayment]="voidPayment" (PaymentResponse)="PaymentResponse($event)" [VerifoneId]="verifoneId" (ClosePaymentPage)="ClosePaymentPage($event)"></app-payment>
    </div>

    <div *ngIf="voidPayment">
        <app-payment [VendorId]="vendorId" [voidPayment]="voidPayment" (PaymentResponse) = voidPaymentResponse() [VerifoneId]="verifoneId"></app-payment>
    </div>

    <div *ngIf = "showSignatureComponent">
        <app-signature-capture  [vendorId]="vendorId" [MarketAccountReceiptId]="MarketAccountReceiptId" [marketId] = "marketId" (dataEmitter)="setSignatureBase64($event)" (getSignatureComponentSign)="getSignatureComponentSign($event)"></app-signature-capture>
      </div>

</form>

<div bsModal #printReceiptPopup="bs-modal" class="modal fade" tabindex="-1" role="dialog"
aria-labelledby="dialog-sizes-name1" [config]="{backdrop: 'static', keyboard: false}">
<div class="modal-dialog modal-dialog-centered modal-fullSize  modal-xl modal-xxl">
    <div class="modal-content" style="margin-top: 3rem;">
      <div class="modal-header">
         <h5 class="modal-title">Preview Receipt</h5>
         <button type="button" class="close" (click)="closeReceiptDisplay()" aria-label="Close">
            <span aria-hidden="true">&times;</span>
         </button>
      </div>
      <div class="modal-body previewModal" style="max-height:76vh ;">
         <div>
            <iframe id="printf" #printf name="printf" style="width:100%; height:73.5vh">
            </iframe>
         </div>
      </div>
   </div>
</div>
</div>

<div bsModal #printContractPopup="bs-modal" class="modal fade" tabindex="-1" role="dialog"
    aria-labelledby="dialog-sizes-name1">
    <div class="modal-dialog modal-dialog-centered modal-fullSize  modal-xl modal-xxl">
        <div class="modal-content" style="margin-top: 3rem;">
            <div class="modal-header">
                <h5 class="modal-title">Preview Contract</h5>
                <button type="button" class="close" (click)="closePrintContractPopup()" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body previewModal" style="max-height:76vh ;">
                <div>
                    <iframe id="printContract" #printContract name="printf" style="width:100%; height:73.5vh;">
                    </iframe>
                </div>
            </div>
        </div>
    </div>
</div>
