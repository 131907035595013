<app-loader *ngIf="loaderIcon | async"></app-loader>
<div class="top_details mb-1" style="padding-bottom: 0px;">
  <ul>
      <li class="mt-0"><label>Vendor ID</label> <span class="mx-2">:</span>{{vendorMarketID}}</li>
      <li class="mt-0"><label>Name</label> <span class="mx-2">:</span>{{vendorName}}</li>
      <li class="mt-0"><label>Phone</label><span class="mx-2">:</span>{{cellPhone}}</li>
      <li class="mt-0"><label>Email</label><span class="mx-2">:</span>{{Email}}</li>
      <li class="mt-0 d-none"><label>Address</label><span class="mx-2">:</span>{{Address}}</li>
      <li class="mt-0"><label>City</label><span class="mx-2">:</span>{{City}}</li>
  </ul>
</div>
<div class="row ml-1 pr-3"> 
  <section class="upperTabSection w-100">    
    <div class="row mx-0 justify-content-between">
      <ul class="nav nav-tabs w-100" id="signatureTab" role="tablist">
        <li class="nav-item">
          <a class="nav-link tabslink active" id="drawSignature-tab" data-toggle="tab" href="#Signature" role="tab" aria-controls="Signature" aria-selected="true" style="min-width: 200px; text-align: center;" (click)="onTabChange($event)">
            Draw Your Signature
          </a>
        </li>
        <li class="nav-item">
          <a class="nav-link tabslink" id="drawSignatureWithSign-tab" data-toggle="tab" href="#SignaturewithSignPad" role="tab" aria-controls="SignaturewithSignPad" aria-selected="false" style="min-width: 250px; text-align: center;" (click)="onTabChange($event)">
            Draw Your Signature With SignPad
          </a>
        </li>
      </ul>
    </div>


    <div class="row mx-0">
      <div class="col-md-6 pr-0 pl-0">
        <div class="tab-content" id="lettPDFTabContent">
          <div class="tab-pane show active" id="Signature" role="tabpanel" aria-labelledby="drawSignature-tab">
            <div class="row mx-0">
              <div class="temp-signature-box temp-signature-box-clr col-lg-12" style="height:150px; border: 2px solid #007bff; border-radius: 5px; background-color: white;">
                <canvas 
                  #normalSignatureCanvas 
                  id="normalSignatureCanvas" 
                  style="border: none; width: 643px; height: 150px;" 
                  aria-placeholder="Draw Your Signature Here">
                </canvas>                
              </div>
            </div>
          </div>
          <div class="tab-pane" id="SignaturewithSignPad" role="tabpanel" aria-labelledby="drawSignatureWithSign-tab">
            <div class="row mx-0">
              <div *ngIf="isTopazSignatureEnabled" class="temp-signature-box temp-signature-box-clr col-lg-12" style="height:150px;border: 2px solid #007bff; border-radius: 5px; background-color: white;">
                <canvas #TopazCanvas width="620" height="140" style="border: none;"></canvas>
              </div>
            </div>
          </div>          
        </div>
      </div>
      <div class="col-md-6 temp-signature-box">
        <div class="temp-signature-box-with-padding row">
          <div class="col-lg-12 float-right">          
              <div class="row float-right mt-3">
              <button type="button" class="btn btn-outline-primary px-4" (click)="clearSignature('normalSignatureCanvas')">Clear</button>
              <button type="button" class="btn btn-primary px-4 mx-3" (click)="saveSignature(isTopazSign ? 'TopazCanvas' : 'normalSignatureCanvas')">Confirm</button>
              <button type="button" class="btn btn-outline-primary px-4" (click)="showConfirmation('canvasId')">Cancel</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</div>
