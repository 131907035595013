import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root',
})

export class AdminPrinterSettingService {
    private baseURL: string;
    constructor(private http: HttpClient) {
            this.baseURL = environment.apiBaseUrl;
    }

    private options = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };

    getPrinterSettings(marketId): Observable<boolean> {
        return this.http.get<any>(
            `${this.baseURL}/admin/PrinterSettings/GetPrinterSettings?marketId=` + marketId,
            { observe: 'response' })
            .pipe(
                map(res => {
                    const generalSettings = res.body as any;
                    return generalSettings;
                }),
                catchError(this.handleError)
            );
    }

    getPrinterAdditionalSettings(marketId): Observable<boolean> {
        return this.http.get<any>(
            `${this.baseURL}/admin/PrinterSettings/GetPrinterAdditionalSettings?marketId=` + marketId,
            { observe: 'response' })
            .pipe(
                map(res => {
                    const printerSettings = res.body as any;
                    return printerSettings;
                }),
                catchError(this.handleError)
            );
    }

    savePrinterSettings(printerSettings): Observable<any> {
        const options = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
        const url = `${this.baseURL}/admin/PrinterSettings/SavePrinterSettings`;
        return this.http.post<any>(url, printerSettings, options)
            .pipe(
                map(res => {
                    const response = res as any;
                    return response;
                }),
                catchError(this.handleError)
            );
    }

    savePrinterAdditionalSettings(printerAdditionalSettings): Observable<any> {
        const options = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
        const url = `${this.baseURL}/admin/PrinterSettings/SavePrinterAdditionalSettings`;
        return this.http.post<any>(url, printerAdditionalSettings, options)
            .pipe(
                map(res => {
                    const response = res as any;
                    return response;
                }),
                catchError(this.handleError)
            );
    }

    getSharedPrinters(): Observable<boolean> {
        return this.http.get<any>(
            `${this.baseURL}/admin/PrinterSettings/ListSharedPrinters`,
            { observe: 'response' })
            .pipe(
                map(res => {
                    const printerList = res.body as any;
                    return printerList;
                }),
                catchError(this.handleError)
            );
    }

    displayCustomPrinterSettings(marketId): Observable<boolean> {
        return this.http.get<any>(
            `${this.baseURL}/admin/PrinterSettings/DisplayCustomPrinterSettings?marketId=` + marketId,
            { observe: 'response' })
            .pipe(
                map(res => {
                    const printerSettings = res.body as any;
                    return printerSettings;
                }),
                catchError(this.handleError)
            );
    }

    GetUfmprinterSetting(marketId): Observable<boolean> {
        return this.http.get<any>(
            `${this.baseURL}/admin/UfmprinterSetting/GetUfmprinterSetting?marketId=` + marketId,
            { observe: 'response' })
            .pipe(
                map(res => {
                    const printerSettings = res.body as any;
                    return printerSettings;
                }),
                catchError(this.handleError)
            );
    }

    saveUfmprinterSetting(printerSettings): Observable<any> {
        const options = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
        const url = `${this.baseURL}/admin/UfmprinterSetting/SaveOrUpdateUfmprinterSetting`;
        return this.http.post<any>(url, printerSettings, options)
            .pipe(
                map(res => {
                    const response = res as any;
                    return response;
                }),
                catchError(this.handleError)
            );
    }

    private handleError(error: HttpErrorResponse) {
        console.error('server error:', error);
        if (error.error instanceof Error) {
            const errMessage = error.error.message;
            // this.appinsights.logEvent(errMessage);
            return throwError(errMessage);
        }
        return throwError(error || 'Node.js server error');
    }
}