import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppService } from '../app.service';
import { environment } from 'src/environments/environment';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { ISignatureCapture } from 'src/app/Shared/models/VendorsModel';

@Injectable({
  providedIn: 'root'
})
export class IntegrationService {
  private baseURL: string;
  constructor(private http: HttpClient) {
    this.baseURL = environment.apiBaseUrl;;
  }
  private options = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };

  private handleError(error: HttpErrorResponse) {
    console.error('server error:', error);
    if (error.error instanceof Error) {
      const errMessage = error.error.message;
      // this.appinsights.logEvent(errMessage);
      return throwError(errMessage);
    }
    return throwError(error || 'Node.js server error');
  }

  GetSignatureCapture(marketId): Observable<any> {    
    return this.http.get<any>(
      `${this.baseURL}/Integration/GetSignatureCapture?marketId=` + marketId,      
      { observe: 'response' })
      .pipe(
        map(res => {
          const result = res as any;
          return result;
        }),
        catchError(this.handleError)
      );
  }


  SaveSignatureCaptureDetails(SignatureCapture: ISignatureCapture[]): Observable<any> {
    const options = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
    const url = `${this.baseURL}/Integration/SaveSignatureCaptureDetails`;
    return this.http.post<any>(url, SignatureCapture, options)
      .pipe(
        map(res => {
          const response = res as any[];
          return response;
        }),
        catchError(this.handleError)
      );
  }
}
